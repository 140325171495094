import React from "react"
import Typography from "aldoo-ra/Typography"
import Icon from "aldoo-ra/Icon"
import { BookOpenIcon } from "@heroicons/react/24/outline"
import PageLike from "./page-like"

const PostCard = ({
  post,
  showReadTime = true,
  showLikes = true,
  showCategories = true,
}) => {
  // Post URL
  const href = `/blog/${post.slug}`

  const borderStyle = post.border ? "border-[1px] border-primary" : ""

  const formatDate = (dateString) => {
    const date = new Date(dateString || "2024-10-23")
    // Changed date format to numeric DD.MM.YYYY
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  const handleLikeClick = (e) => {
    e.preventDefault() // Prevent card click when clicking like button
  }

  return (
    <a
      href={href}
      className="flex flex-col w-full col-span-12 md:col-span-6 lg:col-span-4 max-w-[440px] md:max-w-full lg:max-w-[464px] cursor-pointer group hover:bg-primary_lt rounded-[12px] p-4 mx-auto md:mx-0 no-underline"
    >
      {/* Image Container */}
      <div className={`relative w-full aspect-[432/256] rounded-[12px] overflow-hidden bg-gray_4 mb-6 md:mb-6 lg:mb-6 xl:mb-8 ${borderStyle}`}>
        {showReadTime && (
          <div className="absolute flex items-center justify-center top-[20px] right-[25px] bg-white/20 backdrop-blur-md text-sm font-semibold text-white px-[12px] py-[6px] rounded-full z-10">
            <BookOpenIcon className="w-4 h-4 mr-[6px]" />
            {post.read_time || "4 min"}
          </div>
        )}
        <img
          src={post.thumbnail}
          alt={post.title}
          className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
        />
      </div>
      {/* Content */}
      <div className="flex flex-col gap-3 md:gap-[15px]">
        {/* Categories */}
        {showCategories && (
          <div className="flex flex-wrap gap-2">
            {post.category.map((category, index) => (
              <Typography
                key={index}
                variant="info_12_accent"
                className="uppercase text-gray_1 tracking-custom_wide_2 my-0"
              >
                {category.title}
                {index < post.category.length - 1 ? "," : ""}
              </Typography>
            ))}
          </div>
        )}
        {/* Title */}
        <Typography variant="heading_20" className="line-clamp-2 my-0">
          {post.title}
        </Typography>
        {/* Likes and Date */}
        <div className="flex gap-5 justify-start items-center">
          {showLikes && (
            <div onClick={handleLikeClick}>
              <PageLike target={post} />
            </div>
          )}
          <div className="flex items-center justify-start gap-1">
            <Icon id="date" className="w-3 md:w-4 h-3 md:h-4 text-gray_1" />
            <Typography variant="info_12" className="text-gray_1 my-0">
              {formatDate(post.publish_date)}
            </Typography>
          </div>
        </div>
      </div>
    </a>
  )
}

export default PostCard