import React, { useState, useEffect } from "react"
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline"
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import { api } from "aldoo-ra/API"
import { showNotification } from "aldoo-ra/Notification"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import PlatformAuth from "aldoo-ra/PlatformAuth"
import LikeStatusAggregator from "./utils/like-status-aggregator"
import PubSub from "pubsub-js"

const likeAPI = api("likes")
const statusAggregator = LikeStatusAggregator.getInstance()

const PageLike = ({ 
  target,
  variant = "like_btn",  // Allow variant override while maintaining default
  label,                 // Optional label prop
  className = ""        // Optional className prop for additional styling
}) => {
  const [likes, setLikes] = useState(target?.likes || 0)
  const [hasLiked, setHasLiked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLikeAnimating, setIsLikeAnimating] = useState(false)
  const [auth] = PlatformAuth.useAuth()

  // Create a unique topic for this content
  const likeTopic = target?._id ? `likes-${target._id}` : null

  useEffect(() => {
    // Subscribe to likes for this content
    let token
    if (likeTopic) {
      token = PubSub.subscribe(likeTopic, (topic, data) => {
        // Update likes when a change is published
        setLikes(data.likes)
        setHasLiked(data.hasLiked)
      })
    }

    // Initial like status check
    checkLikeStatus()

    // Cleanup subscription
    return () => {
      if (token) {
        PubSub.unsubscribe(token)
      }
    }
  }, [target?._id])

  const checkLikeStatus = async () => {
    if (!target?._id) return
    try {
      const identifier = await getIdentifier()
      // Use aggregator instead of direct API call
      const result = await statusAggregator.getStatus(
        target._id,
        identifier,
        !auth
      )
      setHasLiked(result.hasLiked)
      setLikes(result.likes || 0)
      setIsLoading(false)
    } catch (error) {
      console.error("Failed to check like status:", error)
      showNotification({
        message: "Failed to check like status",
        className: "bg-red-500 text-white",
      })
      setIsLoading(false)
    }
  }

  const handleLike = async (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (isLoading || !target?._id) return

    const previousState = {
      likes,
      hasLiked,
    }

    // Trigger like animation
    setIsLikeAnimating(true)
    setTimeout(() => setIsLikeAnimating(false), 600)

    // Optimistically update UI
    const newHasLiked = !hasLiked
    setHasLiked(newHasLiked)
    setLikes((prev) => prev + (hasLiked ? -1 : 1))

    try {
      const identifier = await getIdentifier()
      const action = hasLiked ? "unlike" : "like"
      const response = await likeAPI({
        [action]: {
          contentId: target._id,
          identifier,
          contentType: target.content?.type || "BlogPost",
          isAnonymous: !auth,
        },
      })

      if (response.error) {
        throw new Error(response.error)
      }

      // Publish like update to all instances of this content
      if (likeTopic) {
        PubSub.publish(likeTopic, {
          likes: response.likes,
          hasLiked: action === "like",
        })
      }

      // Update local state to match server response
      setLikes(response.likes)
      setHasLiked(action === "like")
    } catch (error) {
      console.error("Failed to process like:", error)
      // Revert to previous state
      setLikes(previousState.likes)
      setHasLiked(previousState.hasLiked)
      showNotification({
        message: "Failed to update like",
        className: "bg-red-500 text-white",
      })
    }
  }

  const getIdentifier = async () => {
    if (auth?._id) {
      return auth._id
    }
    if (!window.fpPromise) {
      window.fpPromise = FingerprintJS.load()
    }
    const fp = await window.fpPromise
    const result = await fp.get()
    return result.visitorId
  }

  return (
    <Button
      onClick={handleLike}
      disabled={isLoading}
      variant={variant}
      className={className}
    >
      {label && (
        <span className="mr-2">{label}</span>
      )}

      {hasLiked ? (
        <HeartIconSolid
          className={`
            w-3 md:w-4 h-3 md:h-4 text-primary
            ${
              isLikeAnimating
                ? "scale-125 transition-transform"
                : "hover:scale-110"
            }
            transition-transform duration-200 ease-in-out
            mr-1
          `}
        />
      ) : (
        <HeartIconOutline
          className={`
            w-3 md:w-4 h-3 md:h-4 text-gray-500
            ${
              isLikeAnimating
                ? "scale-125 transition-transform"
                : "hover:scale-110"
            }
            transition-transform duration-200 ease-in-out
            mr-1
          `}
        />
      )}

      <Typography
        variant="info_12"
        className="
          text-gray_1
          transition-all
          duration-200
        "
      >
        {likes}
      </Typography>
    </Button>
  )
}

export default PageLike