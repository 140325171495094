import { api } from "aldoo-ra/API"

const likeAPI = api("likes")

class LikeStatusAggregator {
    static instance = null;
    static BATCH_DELAY = 100; // Reduced delay for more responsive handling

    constructor() {
        this.pendingRequests = new Map();
        this.timeoutId = null;
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new LikeStatusAggregator();
        }
        return this.instance;
    }

    async getStatus(contentId, identifier, isAnonymous) {
        // If there's only one request, process immediately
        if (this.pendingRequests.size === 0) {
            try {
                const response = await likeAPI({
                    "bulk-status": {
                        requests: [{
                            contentId,
                            identifier,
                            isAnonymous
                        }]
                    }
                });

                if (response.error) {
                    throw new Error(response.error);
                }

                // Return the first (and only) result
                return response.results[0];
            } catch (error) {
                console.error("Failed to get like status:", error);
                // Return a default status in case of error
                return {
                    contentId,
                    hasLiked: false,
                    likes: 0
                };
            }
        }

        // If there are pending requests, use the batching mechanism
        return new Promise((resolve, reject) => {
            // Create a unique key for this request
            const requestKey = `${contentId}-${identifier}`;

            // Add to pending requests
            this.pendingRequests.set(requestKey, {
                contentId,
                identifier,
                isAnonymous,
                resolve,
                reject
            });

            // Clear existing timeout
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            // Set new timeout
            this.timeoutId = setTimeout(() => {
                this.processBatch();
            }, LikeStatusAggregator.BATCH_DELAY);
        });
    }

    async processBatch() {
        if (this.pendingRequests.size === 0) return;

        // Create batch of requests
        const batch = Array.from(this.pendingRequests.values()).map(
            ({ contentId, identifier, isAnonymous }) => ({
                contentId,
                identifier,
                isAnonymous
            })
        );

        // Clear pending requests
        const currentRequests = new Map(this.pendingRequests);
        this.pendingRequests.clear();

        try {
            // Make bulk API call
            const response = await likeAPI({
                "bulk-status": {
                    requests: batch
                }
            });

            if (response.error) {
                // Handle batch error
                currentRequests.forEach(({ reject }) => {
                    reject(new Error(response.error));
                });
                return;
            }

            // Distribute results
            response.results.forEach(result => {
                const requestKey = `${result.contentId}-${result.identifier}`;
                const request = currentRequests.get(requestKey);
                if (request) {
                    request.resolve(result);
                }
            });
        } catch (error) {
            // Handle network error
            console.error("Bulk status request failed:", error);
            currentRequests.forEach(({ reject }) => {
                reject(error);
            });
        }
    }
}

export default LikeStatusAggregator;